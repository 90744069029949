<template>
  <div class="tag-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增标签</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="标签名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.labelName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="标签名称" prop="labelName">
            <el-input v-model.trim="params.labelName" autocomplete="off" maxlength="10" placeholder="请填写标签名称"></el-input>
          </el-form-item>
          <el-form-item label="关联卡券" prop="cardIds">
            <template v-if="cardList.length > 0">
              <el-checkbox-group v-model="params.cardIds">
                <el-checkbox v-for="item in cardList" :key="item.cardId" :label="item.cardId">{{ item.cardName
                }}{{ item.cardStatus==0?'(已下架)':'' }}</el-checkbox>
              </el-checkbox-group>
            </template>
            <template v-else>
              <el-empty description="暂无卡券，请先创建卡券"></el-empty>
            </template>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCardList,
  getTableList,
  getTableEdit,
  getTableDel
} from "@/api/card/tag";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        labelId: "",
        labelName: "",
        cardIds: [],
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // 卡券列表
      cardList: [],
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
      this.params.merchantId = JSON.parse(merchantShopId)[0];
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getTableList();// 【请求】卡券标签列表
    this.getCardList();// 【请求】卡券列表
  },
  methods: {
    // 【请求】卡券标签列表
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      });
    },

    // 【请求】卡券列表
    getCardList() {
      let data = {
        merchantId: this.tableParams.merchantId,//商户ID
        shopId: this.tableParams.shopId,//店铺ID
        page: 1,
        size: 0
      };
      getCardList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data.list;
        }
      });
    },

    // 【请求】新增标签
    getTableEdit() {
      let data = this.params;
      data.cardIds = this.params.cardIds.toString();
      getTableEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getTableList();
        }
      });
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增标签";
      this.params = {
        labelId: "",
        labelName: "",
        cardIds: [],
        merchantId: this.params.merchantId,//商户ID
        shopId: this.params.shopId,//店铺ID
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑标签";
      let arr = [];
      for (let i of row.cardIds.split(',')) {
        arr = [...arr, Number(i)]
      }
      row.cardIds = arr;
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = row;
      this.$confirm('你确定要删除该标签吗?', '删除标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getTableDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableList();
          }
        })
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getTableEdit();
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.tag-list {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>