import request from '@/utils/request'
// 卡券列表
export function getCardList(data) {
  return request('post', '/apm/cardCoupon/cardList', data)
}

// 卡券标签列表
export function getTableList(data) {
  return request('post', '/apm/cardLabel/cardLabelList', data)
}

// 新增修改卡券标签
export function getTableEdit(data) {
  return request('post', '/apm/cardLabel/create', data)
}

// 删除
export function getTableDel(data) {
  return request('post', '/apm/cardLabel/delete', data)
}

